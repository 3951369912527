<template>
  <div class="footer-container p-5 mt-7 text-center">
    <a class="text-center text-green a-footer my-5" href="https://diginut.de" target="_blank">© 2020 Diginut GmbH</a>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>


<style scoped>
.footer-container {
  box-shadow: 0 0 10px rgba(0,0,0,.1);
}
.a-footer {
  text-decoration: none;
}
</style>