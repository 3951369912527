<template>
    <div>
      
      <p>Eine Einschätzung, ob sich das Investment in eine Immobilie lohnt, kann mit Hilfe der folgenden vier Kennzahlen gewonnen werden:</p>
      <p class=" mt-4 mb-1"><a class="a-description" href="#brutYield"># Bruttomietrendite</a></p> 
      <p class="mb-1"><a class="a-description" href="#netYield"># Nettomietrendite</a></p> 
      <p class="mb-1"><a class="a-description" href="#returnOnEquity"># Eigenkapitalrendite</a></p> 
      <p class="mb-4"><a class="a-description" href="#annuity"># Annuität</a></p> 

      <p>Wie diese Kennzahlen berechnet werden können, wollen wir anhand eines Beispiels mit folgenden Parametern erklären:</p>

      <!-- Basic Parameter -->
      <p class="mt-4"><strong>Basisparameter</strong></p>
      <div class="div-table">  
        <table class="table table-sm table-bordered border-dark text-dark-blue">
          <thead>
            <tr>
              <th class="text-center" scope="col">Parameter</th>
              <th class="text-center" scope="col">Beschreibung</th>
              <th class="text-center" scope="col">Beispielwert</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Kaufpreis</td>
              <td>Kaufpreis der Immobilie</td>
              <td class="text-right">160.000 €</td>
            </tr>
            <tr>
              <td>Darlehn</td>
              <td>Bankdarlehn für die Immobilie</td>
              <td class="text-right">120.000 €</td>
            </tr>
            <tr>
              <td>Jährlicher Zinssatz</td>
              <td>Jährlicher Zinssatz des Bankdarlehns</td>
              <td class="text-right">2,0 %</td>
            </tr>
            <tr>
              <td>Monatskaltmiete</td>
              <td>Teil der Miete, der allein die Raumnutzung abdeckt</td>
              <td class="text-right">800 €</td>
            </tr>
            <tr>
              <td>Laufzeit</td>
              <td>Laufzeit des Bankdarlehns</td>
              <td class="text-right">20 Jahre</td>
            </tr>
            <tr>
              <td>Jährliche Wertsteigerung</td>
              <td>Erwartete jährliche Wertsteigerung der Immobilie</td>
              <td class="text-right">0,6 %</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Initial Expenses -->
      <p class="mt-4 mb-3"><strong>Anfängliche Kosten</strong></p>
      <div class="div-table">
        <table class="table table-sm table-bordered border-dark text-dark-blue">
          <thead>
            <tr>
              <th class="text-center" scope="col">Parameter</th>
              <th class="text-center" scope="col">Beschreibung</th>
              <th class="text-center" scope="col">Beispielwert</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Grunderwerbsteuer (3,5–6,5 %)</td>
              <td>Steuer, die beim Erwerb eines Grundstücks oder Grundstückanteils anfällt.</td>
              <td class="text-right">8.000 €</td>
            </tr>
            <tr>
              <td>Maklergebühren (4–7 %)</td>
              <td>Gebühren für den Immobilienmakler</td>
              <td class="text-right">8.500 €</td>
            </tr>
            <tr>
              <td>Notar und Grundbuchgebühren (2 %)</td>
              <td>Notarkosten für die Eintragung ins Grundbuch</td>
              <td class="text-right">3.200 €</td>
            </tr>
            <tr>
              <td>Instandsetzungskosten</td>
              <td>Kosten die anfallen können, um eine Immobilie wieder in Betrieb zu nehmen</td>
              <td class="text-right">1.000 €</td>
            </tr>
            <tr>
              <td>Sonstige Kosten</td>
              <td>Sonstige einmalige Kosten</td>
              <td class="text-right">500 €</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Yearly Expenses -->
      <p class="mt-4"><strong>Jährliche Kosten</strong></p>
      <div class="div-table">
        <table class="table table-sm table-bordered border-dark text-dark-blue">
          <thead>
            <tr>
              <th class="text-center" scope="col">Parameter</th>
              <th class="text-center" scope="col">Beschreibung</th>
              <th class="text-center" scope="col">Beispielwert</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Verwaltungskosten</td>
              <td>Jährlich anfallende Verwaltungskosten</td>
              <td class="text-right">500 €</td>
            </tr>
            <tr>
              <td>Instandhaltungskosten (ca. 10€ / m<sup>2</sup>)</td>
              <td>Jährlich anfallende Instandhaltungskosten für die Wohnung</td>
              <td class="text-right">600 €</td>
            </tr>
            <tr>
              <td>Instandhaltungsrücklage (ca. 1 % Kaufpreis)</td>
              <td>Instandhaltungsrücklage für das Gebäude</td>
              <td class="text-right">1.600 €</td>
            </tr>
          </tbody>
        </table>
      </div>

      <hr class="mt-6">

      <!-- Brut Yield -->
      <h4 id="brutYield" class="mt-6"># Bruttomietrendite</h4>
      <p>Bei der Bruttomietrendite teilt man die Jahreskaltmiete durch den Kaufpreis und multipliziert das Ergebnis mit 100, um eine Angabe in Prozent zu erlangen. Die Bruttomietrendite sollte mindestens 5 % betragen. Wenn man die oben aufgelisteten Beispielparameter in die Formel einsetzt, gelangt man zu folgendem Ergebnis:</p>
      <div class="row align-items-center mt-3 border border-3 rounded pt-3">
        <div class="col-md-4">
          <div>
            <p class="text-center fst-italic mb-0">Jahreskaltmiete</p>
            <hr class="my-1" />
            <p class="text-center fst-italic">Kaufpries</p>
          </div>
        </div>
        <div class="col-md-1">
          <p class="text-center">=</p>
        </div>
        <div class="col-md-4">
          <div>
            <p class="text-center fst-italic mb-0">9.600 €</p>
            <hr class="my-1" />
            <p class="text-center fst-italic">160.000 €</p>
          </div>
        </div>
        <div class="col-md-1">
          <p class="text-center">=</p>
        </div>
        <div class="col-md-2">
          <p class="text-center"><strong>6 %</strong></p>
        </div>
      </div>

      <!-- Net Yield -->
      <h4 id="netYield" class="mt-6"># Nettomietrendite</h4>
      <p>Noch wichtiger als die Bruttomietrendite ist die Nettomietrendite. Dabei teilt man die Jahreskaltmiete abzüglich aller jährlichen Kosten durch den Kaufpreis plus alle anfänglichen Kosten und multipliziert das Ergebnis mit 100, um eine Angabe in Prozent zu erlangen. Die Nettomietrendite sollte mindestens 3.5 % betragen. Wenn man die oben aufgelisteten Beispielparameter in die Formel einsetzt, gelangt man zu folgendem Ergebnis:</p>
      <div class="row align-items-center mt-3 border border-3 rounded pt-3">
        <div class="col-md-4">
          <div>
            <p class="text-center fst-italic mb-0">(Jahreskaltmiete - jährliche Kosten)</p>
            <hr class="my-1" />
            <p class="text-center fst-italic">(Kaufpries + anfängliche Zusatzkosten)</p>
          </div>
        </div>
        <div class="col-md-1">
          <p class="text-center">=</p>
        </div>
        <div class="col-md-4">
          <div>
            <p class="text-center fst-italic mb-0">(9.600 € - 500 € - 600 € - 1600 €)</p>
            <hr class="my-1" />
            <p class="text-center fst-italic">(160.000 € + 8000 € + 8500 € + 3200 € + 1000 € + 500 €)</p>
          </div>
        </div>
        <div class="col-md-1">
          <p class="text-center">=</p>
        </div>
        <div class="col-md-2">
          <p class="text-center"><strong>3,8 %</strong></p>
        </div>
      </div>

      <!-- Return On Equity -->
      <h4 id="returnOnEquity" class="mt-6"># Eigenkapitalrendite</h4>
      
      <p>Eine weitere aussagekräftige Kennzahl, ist die Eigenkapitalrendite. Dabei wird das Eigenkapital mit allen Kosten und Erträgen, die bis zur Darlehnstilgung anfallen ins Verhältnis gesetzt. Auf der Kostenseite, stehen dabei das Eigenkapital plus das Darlehn inklusive Zinsen abzüglich aller Mietreinerträge. Auf der Ertragsseite seht der erwartete Wert der Immobile nach der Tilgung des Darlehns. Unter Verwendung der oben aufgelisteten Beispielwerte, entsteht folgende Rechnung:</p>      

      <p>Aus dem Kaufpreis und den Anfänglichen Kosten, ergibt sich folgender Betrag:<br>160.000 € + 8.000 € + 8500 € + 3.200 € + 1.000 € + 500 € = <strong>181.200 €</strong>.</p>
      
      <p class="mb-1">Aus dem daraus berechneten Werten:</p>
      <ul class="mb-1" style="font-size: 18px;">
        <li>Eigenkapital: 61.200 € (Kaufpreis und anfängliche Kosten minus Darlehn)</li>
        <li>Darlehen: 120.000 €</li>
        <li>Zinsen für Annuitätendarlehen über 20 Jahre: 26.780 € (siehe # Annuität)</li>
      </ul>
      <p>ergibt sich die Summe: <strong>207.980 €</strong>.</p>

      <p>Mit den Mietreinerträgen bis zur Darlehnstilgung von:<br>((800 € * 12 €) -  500 € – 600 € - 1600 € ) * 20 = <strong>138.000 €</strong></p>

      <p>erlangt man ein Eigenkapital von<br>207.980 € - 138.000 € = <strong>69.980 €.</strong></p>

      <p>Der Immobilienwert nach 20 Jahren bei einer durchschnittlichen jährlichen Wertsteigerung von 0,6 % ergibt dann <strong>180.335 €</strong>.</p>

      <p>Wenn man die Immobilie nach 20 Jahren verkaufen würde, wäre der Erlös 180.335 € - 69.980 € = <strong>110.355 €</strong>.</p>

      <p>Daraus ergibt sich ein jährlicher Erlös von 110.355 € / 20 = <strong>5.517 €</strong>.</p>

      <p>Dadurch lässt sich eine Eigenkapitalrendite von: 5.517 € / 69.980 € = <strong>7,9 %</strong> berechnen.</p>

      <div class="row align-items-center mt-3 border border-3 rounded pt-3">
        <div class="col-md-4">
          <div>
            <p class="text-center fst-italic mb-0">Jährlicher Erlös</p>
            <hr class="my-1" />
            <p class="text-center fst-italic">Eigenkapital</p>
          </div>
        </div>
        <div class="col-md-1">
          <p class="text-center">=</p>
        </div>
        <div class="col-md-4">
          <div>
            <p class="text-center fst-italic mb-0">5.517 €</p>
            <hr class="my-1" />
            <p class="text-center fst-italic">69.980 €</p>
          </div>
        </div>
        <div class="col-md-1">
          <p class="text-center">=</p>
        </div>
        <div class="col-md-2">
          <p class="text-center"><strong>7,9 %</strong></p>
        </div>
      </div>

      <!-- Annuity -->
      <h4 id="annuity" class="mt-6"># Annuität</h4>

      <!-- Description -->
      <p>Bei einer mit Fremdkapital finanzierten Immobilie, ist die Annuität, eine sehr wichtige Kennzahl für die Berechnung der Darlehnstilgung. In den Wirtschaftswissenschaften bzw. der Finanzmathematik bezeichnet man als Annuität eine regelmäßig jährlich fließende Zahlung, die sich aus den Elementen Zins und Tilgung zusammensetzt. Der Betrag der jährlichen Zahlung bleibt dabei gleich. Das Verhältnis von den jährlich bezahlten Zinsen und Tilgung verändert sich. Da sich von Jahr zu Jahr die Restschuld verkleinert, verkleinert sich der Betrag der bezahlten Zinsen und somit vergrößert sich der Betrag der Tilgung. Die Annuität lässt sich mit folgender Formel berechnen.</p>

      <!-- Formula -->
      <div class="border border-3 rounded p-3 mt-3">
        <div class="row align-items-center">
          <div class="col-md-4">
            <div>
              <p class="text-center fst-italic mb-0">K * q<sup>n</sup> * (q-1)</p>
              <hr class="my-1" />
              <p class="text-center fst-italic">q<sup>n</sup> - 1</p>
            </div>
          </div>
          <div class="col-md-1">
            <p class="text-center">=</p>
          </div>
          <div class="col-md-4">
            <div>
              <p class="text-center fst-italic mb-0">120.000 € * 1,02<sup>20</sup> * (1,02-1)</p>
              <hr class="my-1" />
              <p class="text-center fst-italic">1,02<sup>20</sup> - 1</p>
            </div>
          </div>
          <div class="col-md-1">
            <p class="text-center">=</p>
          </div>
          <div class="col-md-2">
            <p class="text-center"><strong>7.339 €</strong></p>
          </div>
        </div>
        <hr>
        <p class="mb-1">K = Darlehn = 120.000 €</p>
        <p class="mb-0">q: 1 + Zins/100 = 1 + 2/100 = 1,02</p>
      </div>

      <!-- List -->
      <p class="my-4">Folgende Liste zeigt den Verlauf von Zinsen und Tilgung für die Werte aus unserem Beispiel:</p>
      <div v-if="annuityArray" class="text-dark-blue div-annuity">
        <div class="mt-4 text-dark-blue">
          <table class="table table-bordered table-hover table-striped table-sm text-dark-blue">
            <thead>
              <tr>
                <th class="text-center" scope="col">Jahr</th>
                <th class="text-center" scope="col">Restschuld</th>
                <th class="text-center" scope="col">Zinsen</th>
                <th class="text-center" scope="col">Tilgung</th>
                <th class="text-center" scope="col">Annuität</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="annuity in annuityArray" :key="annuity.year">
                <th scope="row" class="text-center">{{ annuity.year }}</th>
                <td class="text-right">{{ toCurrencyString(annuity.restLoan) }}</td>
                <td class="text-right">{{ toCurrencyString(annuity.interest) }}</td>
                <td class="text-right">{{ toCurrencyString(annuity.payment) }}</td>
                <td class="text-right">{{ toCurrencyString(annuity.annuity) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
</template>

<script>

import { getAnnuity } from "../../js/helper/annuity";
import { toCurrencyString } from "../../js/helper/toCurrencyString";

export default {
  name: "Description",
  data () {
    return {
      annuityArray: null,
    }
  },
  created() {
    this.annuityArray = getAnnuity(
      120000,
      2,
      20
    );
  },
  methods: {
    toCurrencyString (amount) {
      return toCurrencyString(amount);
    },
  }
};
</script>

<style scoped>
.ul-description {
  font-size: 18px;
  font-weight: bold;
}
.div-table {
  overflow: auto;
}
.a-description {
  text-decoration: none;
}
</style>