<template>
  <div class="container-fluid navbar-container py-4">
    <h4 class="text-center">Renditerechner</h4>
  </div>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style scoped>

.router-link-active {
  color: #42b983;
}
.navbar-router-link {
  text-decoration: none;
  font-size: 18px;
}
.navbar-router-link:hover {
  color: #42b983;
}
.navbar-container {
  box-shadow: 0 0 10px rgba(0,0,0,.1);
}
</style>
