<template>
  <div class="scroll-to-top" v-if="scrollpx > 1">
    <button
      type="button"
      class="btn scroll-to-top active"
      data-toggle="button"
      aria-pressed="true"
      v-on:click="scrollToTop()"
    >
      <i class="fas fa-chevron-up"></i>
    </button>
  </div>
</template>


<script>
export default {
  name: "ScrollToTop",
  data() {
    return {
      scrollpx: 0,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleScroll() {
      this.scrollpx = window.scrollY;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>


<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  opacity: 100;
  transition: all 0.2s ease-in-out;
  background-color: #383e66;
  border-radius: 2px;
  outline: none;
  z-index: 2;
}

.scroll-to-top:hover {
  background-color: #383e66;
  outline: none;
}

.scroll-to-top:focus {
  outline: none;
}

.show-scrollTop {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.fa-chevron-up {
  color: #ffffff !important;
}

.scroll-to-top:hover .btn-scroll-to-top .fa-chevron-up {
  color: #ffffff !important;
}
</style>
