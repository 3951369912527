<template>
  <div id="app">
    <ScrollToTop></ScrollToTop>
    <Navbar></Navbar>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>

import Navbar from "./components/Navbar.vue"
import Footer from "./components/Footer.vue"
import ScrollToTop from "./components/ScrollToTop.vue"

export default {
  components: {
    Navbar,
    Footer,
    ScrollToTop
  }
}
</script>

<style>
</style>
