/**
 * Converts a currency string to a number
 * Decimal seperator: , (comma)
 * Thousand seperator: . (point)
 * @param {String} currencyString 
 */
export function currencyStringToNumber(currencyString) {
    if (currencyString) {
        return Number(currencyString.replace('-', '').replace('.', '').replace(',', '.'));
    } else {
        return null;
    }
}