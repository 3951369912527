<template>
  <div class="container container-calculator">
    
    <!-- Header -->
    <h3 class="mt-6">Wann lohnt sich der Kauf einer Immobilie?</h3>
    <p>Anhand eines Rechenbeispiels werden die vier wichtigsten Kennzahlen, die man für ein Immobilieninvestment benötigt ermittelt. Mit dem Renditen- und Annuitätenrechner lassen sich einfach und schnell diese Kennzahlen des eigenen Investments berechnen.</p>

    <!-- Tab Navigation -->
    <nav class="mt-6 mb-5">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a
          class="nav-link active"
          id="nav-annuity-tab"
          data-toggle="tab"
          href="#nav-annuity"
          role="tab"
          aria-controls="nav-annuity"
          aria-selected="true"
          style="font-size: 18px;"
          >Rechenbeispiel</a
        >
        <a
          class="nav-link"
          id="nav-rental-yields-tab"
          data-toggle="tab"
          href="#nav-rental-yields"
          role="tab"
          aria-controls="nav-rental-yields"
          aria-selected="false"
          style="font-size: 18px;"
          >Renditen- und Annuitätenrechner</a
        >
      </div>
    </nav>
    
    <div class="tab-content py-4 mx-2">
      <div
        class="tab-pane fade show active"
        id="nav-annuity"
        role="tabpanel"
        aria-labelledby="nav-annuity-tab"
      >
        <Description></Description>
      </div>
      <div
        class="tab-pane fade"
        id="nav-rental-yields"
        role="tabpanel"
        aria-labelledby="nav-rental-yields-tab"
      >
        <RentalYields></RentalYields>
      </div>
    </div>

  </div>
</template>

<script>

import Description from "../components/Calculator/Description.vue"
import RentalYields from "../components/Calculator/RentalYields.vue"

export default {
  name: "Calculator",
  components: {
    Description,
    RentalYields,
  },
  data() {
    return {
      loan: null,
      interestRate: null,
      runTime: null,
      annuityArray: null,
    };
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .container-calculator {
    padding-left: 12%;
    padding-right: 12%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .container-calculator {
    padding-left: 7%;
    padding-right: 7%;
  }
}
</style>