export function getRentalYields(investmentParameter) {

    let grossRentalReturn;
    let netRentalReturn;
    let returnOnEquity;

    let realestateValueAfterRunningTime;
    let loanIncludingInterests;
    let initalCapital;
    let totalCapital;
    let totalNetRental;
    let profit;
    let yearlyProfit;
    let q;

    // Gross Rental Return
    // (yearly net rental / price) * 100
    // ----------------------------------------

    grossRentalReturn = Math.round(((investmentParameter.monthlyRent * 12) / investmentParameter.price) * 1000, 2) / 10;

    // Net Rental Return
    // ((yearly net rental - all running costs) / (price + all initial costs)) * 100
    // ----------------------------------------

    netRentalReturn = Math.round(
        (
            (
                (investmentParameter.monthlyRent * 12) -
                (investmentParameter.administrationCosts || 0) -
                (investmentParameter.maintenanceCost || 0) -
                (investmentParameter.maintenanceReserves || 0)
            ) /
            (
                investmentParameter.price +
                (investmentParameter.taxes || 0) +
                (investmentParameter.agentFees || 0) +
                (investmentParameter.notarAndRegistry || 0) +
                (investmentParameter.repairCosts || 0) +
                (investmentParameter.otherCosts || 0)
            )
        ) * 1000
    ) / 10;

    // Return On Equity
    // ( Realestate value after running time - Total capital + Total rentals ) / Running time
    // ----------------------------------------

    q = 1 + (investmentParameter.interestRate / 100);

    // K0 * ((p / 100) + 1) n
    realestateValueAfterRunningTime = investmentParameter.price * Math.pow((((investmentParameter.annualIncrease / 100) || 0) + 1), investmentParameter.runningTime);

    // Annuity * runningTime
    loanIncludingInterests = ((investmentParameter.loan * Math.pow(q, investmentParameter.runningTime) * (q - 1)) / (Math.pow(q, investmentParameter.runningTime) - 1) * investmentParameter.runningTime);

    // (Price - loan) plus all initial costs 
    initalCapital = (
        (investmentParameter.price - investmentParameter.loan) +
        (investmentParameter.taxes || 0) +
        (investmentParameter.agentFees || 0) +
        (investmentParameter.notarAndRegistry || 0) +
        (investmentParameter.repairCosts || 0) +
        (investmentParameter.otherCosts || 0)
    );

    // Investiertes Eigenkapital: Kaufpreis + Initiale Kosten - Darlehn
    totalNetRental = (
        (investmentParameter.monthlyRent * 12) -
        (investmentParameter.administrationCosts || 0) -
        (investmentParameter.maintenanceCost || 0) -
        (investmentParameter.maintenanceReserves || 0)
    ) * investmentParameter.runningTime;

    // Total capital: Invested equity + Loan including payed interests until the running time
    if ((loanIncludingInterests - totalNetRental) <= 0) {
        totalCapital = Math.round(initalCapital);
        profit = realestateValueAfterRunningTime + (totalNetRental - loanIncludingInterests) - totalCapital;
    } else {
        totalCapital = Math.round((initalCapital + loanIncludingInterests - totalNetRental));
        profit = realestateValueAfterRunningTime - totalCapital;
    }

    yearlyProfit = Math.round(profit / investmentParameter.runningTime);

    returnOnEquity = Math.round(((yearlyProfit / totalCapital) * 1000)) / 10;

    return {
        grossRentalReturn: grossRentalReturn,
        netRentalReturn: netRentalReturn,
        returnOnEquity: {
            value: returnOnEquity,
            yearlyProfit: yearlyProfit,
            totalCapital: totalCapital
        }
    };
}