export function getAnnuity(loan, interestRate, runTime) {

    // Variables
    let q;
    let annuity;
    let restLoan;
    let interest;
    let payment;
    let annuityArray = [];

    // Derived values q and annuity
    q = 1 + (interestRate / 100);
    annuity = (loan * Math.pow(q, runTime) * (q - 1)) / (Math.pow(q, runTime) - 1)

    // Construct list

    // List value 1
    restLoan = loan;
    interest = restLoan * interestRate / 100;
    payment = annuity - interest;
    annuityArray.push({
        year: 1,
        restLoan: Math.round(restLoan, 2),
        interest: Math.round(interest, 2),
        payment: Math.round(payment, 2),
        annuity: Math.round(annuity, 2)
    })

    // List value 2 - last year of run time
    for (let i = 2; i <= runTime; i++) {
        restLoan -= payment;
        interest = restLoan * interestRate / 100;
        payment = annuity - interest;
        annuityArray.push({
            year: i,
            restLoan: Math.round(restLoan, 2),
            interest: Math.round(interest, 2),
            payment: Math.round(payment, 2),
            annuity: Math.round(annuity, 2)
        })
    }

    return annuityArray;
}