<template>
  <div>
    <!-- Parameter From -->
    <div>
      
      <!--------------------------------------- Form --------------------------------------->
      
      <!-- Notes -->
      <h5 class="mb-0">Hinweis</h5>
      <ul class="mt-3">
        <li>
          Pflichtfelder: *
        </li>
        <li>
          Dezimaltrennzeichen: Komma (,) 
        </li>
        <li>
          Beträge müssen ohne Punkte als Tausendertrennzeichen eingegeben werden.
        </li>
        <li>
          Für die Währungsbeträge werden nur positive Zahlen akzeptiert. Eingegeben Minus werden bei Währungsbeträgen ignoriert. Negative prozentuale Beträge können durch Minus (-) eingegeben werden.
        </li>
      </ul>
      
      <!-- Basic Parameter -->
      <h5 class="mb-0 mt-5">Basis Parameter</h5>
      <div class="row">
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Kaufpreis *</strong></label>
          <input
            v-on:keyup="validateForm()"
            name="price"
            v-model="price"
            id="price"
            type="number"
            class="form-control"
            v-bind:class="{
              'is-invalid': !priceIsValid && priceIsValid !== null,
            }"
            placeholder="160000 €"
            min="0"
            required
          />
        </div>
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Darlehn *</strong></label>
          <input
            v-on:keyup="validateForm()"
            name="loan"
            v-model="loan"
            id="loan"
            type="number"
            class="form-control"
            v-bind:class="{
              'is-invalid': !loanIsValid && loanIsValid !== null,
            }"
            placeholder="120000 €"
            required
          />
        </div>
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Jährlicher Zinssatz *</strong></label>
          <input
            v-on:keyup="validateForm()"
            name="interestRate"
            v-model="interestRate"
            id="interestRate"
            type="number"
            class="form-control"
            v-bind:class="{
              'is-invalid': !interestRateIsValid && interestRateIsValid !== null,
            }"
            placeholder="2,0 %"
            required
          />
        </div>
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Monatskaltmiete *</strong></label>
          <input
            v-on:keyup="validateForm()"
            name="monthlyRent"
            v-model="monthlyRent"
            id="monthlyRent"
            type="number"
            class="form-control"
            v-bind:class="{
              'is-invalid': !monthlyRentIsValid && monthlyRentIsValid !== null,
            }"
            placeholder="800 €"
            required
          />
        </div>
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Laufzeit *</strong></label>
          <input
            v-on:keyup="validateForm()"
            name="runningTime"
            v-model="runningTime"
            id="runningTime"
            type="number"
            class="form-control"
            v-bind:class="{
              'is-invalid': !runningTimeIsValid && runningTimeIsValid !== null,
            }"
            placeholder="20 Jahre"
            required
          />
        </div>
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Erwartete Jährliche Wertsteigerung</strong></label>
          <input
            name="annualIncrease"
            v-model="annualIncrease"
            id="annualIncrease"
            type="number"
            class="form-control"
            placeholder="0,6 %"
          />
        </div>
      </div>

      <!-- Initial Expenses -->
      <h5 class="mt-5 mb-0">Initiale Kosten</h5>
      <div class="row">
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Grunderwerbsteuer (3,5–6,5%)</strong></label>
          <input
            name="taxes"
            v-model="taxes"
            id="taxes"
            type="number"
            class="form-control"
            placeholder="8000 €"
          />
        </div>
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Maklergebühren (4–7%)</strong></label>
          <input
            name="agentFees"
            v-model="agentFees"
            id="agentFees"
            type="number"
            class="form-control"
            placeholder="8500 €"
          />
        </div>
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Notar und Grundbuchgebühren (2%)</strong></label>
          <input
            name="notarAndRegistry"
            v-model="notarAndRegistry"
            id="notarAndRegistry"
            type="number"
            class="form-control"
            placeholder="3200 €"
          />
        </div>
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Instandsetzungskosten</strong></label>
          <input
            name="repairCosts"
            v-model="repairCosts"
            id="repairCosts"
            type="number"
            class="form-control"
            placeholder="1000 €"
          />
        </div>
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Sonstige Kosten</strong></label>
          <input
            name="otherCosts"
            v-model="otherCosts"
            id="otherCosts"
            type="number"
            class="form-control"
            placeholder="500 €"
          />
        </div>
      </div>

      <!-- Running Expenses -->
      <h5 class="mt-5 mb-0">Jährliche Kosten</h5>
      <div class="row">
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Verwaltungskosten</strong></label>
          <input
            name="administrationCosts"
            v-model="administrationCosts"
            id="administrationCosts"
            type="number"
            class="form-control"
            placeholder="500 €"
          />
        </div>
        <div class="col-md-6 mt-3">
          <label class="form-label"><strong>Instandhaltungskosten (ca. 10€ / m<sup>2</sup>)</strong></label>
          <input
            name="maintenanceCost"
            v-model="maintenanceCost"
            id="maintenanceCost"
            type="number"
            class="form-control"
            placeholder="600 €"
          />
        </div>
        <div class="col-md-6 mt-3">
          <label class="form-label"
            ><strong>Instandhaltungsrücklage (ca. 1% Kaufpreis)</strong></label
          >
          <input
            name="maintenanceReserves"
            v-model="maintenanceReserves"
            id="maintenanceReserves"
            type="number"
            class="form-control"
            placeholder="1600 €"
          />
        </div>
      </div>

      <!-- Calculae Button -->
      <div class="row mt-4">
        <div class="col-md-6">
          <button
            type="button"
            class="btn btn-bright-green-full w-100 mt-4"
            :disabled="!formIsValid"
            v-on:click="calculateRentalYields()"
          >
            Berechne Rendite und Annuität
          </button>
        </div>
      </div>

      <!-------------------------------------- Yields -------------------------------------->
      
      <!-- Yields -->
      <div v-if="yields" class="mt-5 text-dark-blue">
        <h4 class="mt-6"># Renditen</h4>
        <div class="row">
          <div class="col-12 mt-4">
            <div class="border border-3 rounded p-3">
              <h5><strong>Buttomietrendite: {{ yields.grossRentalReturn }} %</strong></h5>
              <hr />
              <div class="row align-items-center mt-4">
                <div class="col-3">
                  <p><strong>Formel</strong></p>
                </div>
                <div class="col-9">
                  <div>
                    <p class="text-center fst-italic mb-0">Jahreskaltmiete</p>
                    <hr class="my-1" />
                    <p class="text-center fst-italic">Kaufpries</p>
                  </div>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <div class="col-3">
                  <p><strong>Eingesetzt</strong></p>
                </div>
                <div class="col-9">
                  <div>
                    <p class="text-center fst-italic mb-0">
                      {{ toCurrencyString(yearlyRental) }}
                    </p>
                    <hr class="my-1" />
                    <p class="text-center fst-italic">{{ toCurrencyString(housePrice) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="border border-3 rounded p-3">
              <h5><strong>Nettomietrendite: {{ yields.netRentalReturn }} %</strong></h5>
              <hr />
              <div class="row align-items-center mt-4">
                <div class="col-3">
                  <p><strong>Formel</strong></p>
                </div>
                <div class="col-9">
                  <div>
                    <p class="text-center fst-italic mb-0">( Jahreskaltmiete - Jährliche Kosten )</p>
                    <hr class="my-1" />
                    <p class="text-center fst-italic">( Kaufpries + Anfängliche Zusatzkosten )</p>
                  </div>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <div class="col-3">
                  <p><strong>Eingesetzt</strong></p>
                </div>
                <div class="col-9">
                  <div>
                    <p class="text-center fst-italic mb-0">
                      ( {{ toCurrencyString(yearlyRental) }} - {{ toCurrencyString(yearlyCosts) }} )
                    </p>
                    <hr class="my-1" />
                    <p class="text-center fst-italic">( {{ toCurrencyString(housePrice) }} + {{ toCurrencyString(initialCosts) }} )</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="border border-3 rounded p-3">
              <h5><strong>Eigenkapitalrendite: {{ yields.returnOnEquity.value }} %</strong></h5>
              <hr />
              <div class="row align-items-center mt-4">
                <div class="col-3">
                  <p><strong>Formel</strong></p>
                </div>
                <div class="col-9">
                  <div>
                    <p class="text-center fst-italic mb-0">Jährlicher Erlös</p>
                    <hr class="my-1" />
                    <p class="text-center fst-italic">Eigenkapital</p>
                  </div>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <div class="col-3">
                  <p><strong>Eingesetzt</strong></p>
                </div>
                <div class="col-9">
                  <div>
                    <p class="text-center fst-italic mb-0">
                      ( {{ toCurrencyString(yields.returnOnEquity.yearlyProfit) }} )
                    </p>
                    <hr class="my-1" />
                    <p class="text-center fst-italic">( {{ toCurrencyString(yields.returnOnEquity.totalCapital) }} )</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Annuity List -->
      <div v-if="annuityArray" class="mt-3 text-dark-blue div-annuity">
        <h4 class="mt-6"># Annuität</h4>
        <div class="mt-4 text-dark-blue">
          <table class="table table-bordered table-hover table-striped table-sm text-dark-blue">
            <thead>
              <tr>
                <th class="text-center" scope="col">Jahr</th>
                <th class="text-center" scope="col">Restschuld</th>
                <th class="text-center" scope="col">Zinsen</th>
                <th class="text-center" scope="col">Tilgung</th>
                <th class="text-center" scope="col">Annuität</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="annuity in annuityArray" :key="annuity.year">
                <th scope="row" class="text-center">{{ annuity.year }}</th>
                <td class="text-right">{{ toCurrencyString(annuity.restLoan) }}</td>
                <td class="text-right">{{ toCurrencyString(annuity.interest) }}</td>
                <td class="text-right">{{ toCurrencyString(annuity.payment) }}</td>
                <td class="text-right">{{ toCurrencyString(annuity.annuity) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { getRentalYields } from "../../js/helper/getRentalYields";
import { toCurrencyString } from "../../js/helper/toCurrencyString";
import { currencyStringToNumber } from "../../js/helper/currencyStringToNumber";
import { getAnnuity } from "../../js/helper/annuity";

export default {
  name: "RentalYields",
  data() {
    return {
      // Results
      yields: null,
      annuityArray: null,
      // Basic parameter
      price: null,
      loan: null,
      interestRate: null,
      monthlyRent: null,
      runningTime: null,
      annualIncrease: null,
      // Initial Expenses
      taxes: null,
      agentFees: null,
      notarAndRegistry: null,
      repairCosts: null,
      otherCosts: null,
      // Running Expenses
      administrationCosts: null,
      maintenanceCost: null,
      maintenanceReserves: null,
      // Yield Equation Vatiables
      yearlyRental: null,
      yearlyCosts: null,
      initialCosts: null,
      housePrice: null,
      // Validations
      formIsValid: false,
      priceIsValid: null,
      loanIsValid: null,
      interestRateIsValid: null,
      monthlyRentIsValid: null,
      runningTimeIsValid: null,
    };
  },
  methods: {

    /**
     * Validate required form fields
     */
    validateForm() {
      this.validatePrice();
      this.validateLoan();
      this.validateInterestRate();
      this.validateMonthlyRent();
      this.validateRunningTimeIsValid();
      if (
        this.priceIsValid &&
        this.loanIsValid &&
        this.interestRateIsValid &&
        this.monthlyRentIsValid &&
        this.runningTimeIsValid
      ) {
        this.formIsValid = true;
      } else {
        this.formIsValid = false;
      }
    },

    /**
     * Price validation
     */
    validatePrice: function () {
      if (this.price === null) return;
      if (this.price) {
        this.priceIsValid = true;
      } else {
        this.priceIsValid = false;
      }
    },
    
    /**
     * Loan validation
     */
    validateLoan: function () {
      if (this.loan === null) return;
      if (this.loan) {
        this.loanIsValid = true;
      } else {
        this.loanIsValid = false;
      }
    },
  
    /**
     * Interest Rate validation
     */
    validateInterestRate: function () {
      if (this.interestRate === null) return;
      if (this.interestRate) {
        this.interestRateIsValid = true;
      } else {
        this.interestRateIsValid = false;
      }
    },

    /**
     * Monthly Rent validation
     */
    validateMonthlyRent: function () {
      if (this.monthlyRent === null) return;
      if (this.monthlyRent) {
        this.monthlyRentIsValid = true;
      } else {
        this.monthlyRentIsValid = false;
      }
    },

    /**
     * Running Time validation
     */
    validateRunningTimeIsValid: function () {
      if (this.runningTime === null) return;
      if (this.runningTime) {
        this.runningTimeIsValid = true;
      } else {
        this.runningTimeIsValid = false;
      }
    },

    calculateRentalYields() {
      this.yields = getRentalYields({
        price: currencyStringToNumber(this.price),
        loan: currencyStringToNumber(this.loan),
        interestRate: this.interestRate,
        monthlyRent: currencyStringToNumber(this.monthlyRent),
        runningTime: currencyStringToNumber(this.runningTime),
        annualIncrease: this.annualIncrease,
        taxes: currencyStringToNumber(this.taxes),
        agentFees: currencyStringToNumber(this.agentFees),
        notarAndRegistry: currencyStringToNumber(this.notarAndRegistry),
        repairCosts: currencyStringToNumber(this.repairCosts),
        otherCosts: currencyStringToNumber(this.otherCosts),
        administrationCosts: currencyStringToNumber(this.administrationCosts),
        maintenanceCost: currencyStringToNumber(this.maintenanceCost),
        maintenanceReserves: currencyStringToNumber(this.maintenanceReserves),
      });
      this.annuityArray = getAnnuity(
        this.loan,
        this.interestRate,
        this.runningTime
      );
      this.yearlyRental = this.monthlyRent * 12;
      this.yearlyCosts = Number(this.administrationCosts) + Number(this.maintenanceCost) + Number(this.maintenanceReserves);
      this.initialCosts = Number(this.taxes) + Number(this.agentFees) + Number(this.notarAndRegistry) + Number(this.repairCosts) + Number(this.otherCosts);
      this.housePrice = this.price;
    },

    toCurrencyString (amount) {
      return toCurrencyString(amount);
    }

  },
  components: {},
};
</script>

<style scoped>
.div-annuity {
  overflow: auto;
}
</style>
